// format: [ signal, type, left, top, [decimals] ]
// type can be slab, pill, or hero
const grid = [
  ['BMS_nominalEnergyRemaining', 'pill', 1, 1],
  ['BMS_packVoltage', 'slab', 2, 1],
  ['BMS_packCurrent', 'slab', 4, 1],
  ['BMS_thermistorTMax', 'pill', 6, 1],

  ['BMS_brick0', 'pill', 1, 2, 2],
  ['BMS_brick2', 'pill', 2, 2, 2],
  ['BMS_brick4', 'pill', 3, 2, 2],
  ['BMS_brick6', 'pill', 4, 2, 2],
  ['BMS_brick8', 'pill', 5, 2, 2],
  ['BMS_brick10', 'pill', 6, 2, 2],

  ['BMS_brick12', 'pill', 1, 3, 2],
  ['BMS_brick14', 'pill', 2, 3, 2],
  ['BMS_brick16', 'pill', 3, 3, 2],
  ['BMS_brick18', 'pill', 4, 3, 2],
  ['BMS_brick20', 'pill', 5, 3, 2],
  ['BMS_brick22', 'pill', 6, 3, 2],

  ['BMS_brick24', 'pill', 1, 4, 2],
  ['BMS_brick26', 'pill', 2, 4, 2],
  ['BMS_brick28', 'pill', 3, 4, 2],
  ['BMS_brick30', 'pill', 4, 4, 2],
  ['BMS_brick32', 'pill', 5, 4, 2],
  ['BMS_brick34', 'pill', 6, 4, 2],

  ['BMS_brick36', 'pill', 1, 5, 2],
  ['BMS_brick38', 'pill', 2, 5, 2],
  ['BMS_brick40', 'pill', 3, 5, 2],
  ['BMS_brick42', 'pill', 4, 5, 2],
  ['BMS_brick44', 'pill', 5, 5, 2],
  ['BMS_brick46', 'pill', 6, 5, 2],

  ['BMS_brick48', 'pill', 1, 6, 2],
  ['BMS_brick50', 'pill', 2, 6, 2],
  ['BMS_brick52', 'pill', 3, 6, 2],
  ['BMS_brick54', 'pill', 4, 6, 2],
  ['BMS_brick56', 'pill', 5, 6, 2],
  ['BMS_brick58', 'pill', 6, 6, 2],

  ['BMS_brick60', 'pill', 1, 7, 2],
  ['BMS_brick62', 'pill', 2, 7, 2],
  ['BMS_brick64', 'pill', 3, 7, 2],
  ['BMS_brick66', 'pill', 4, 7, 2],
  ['BMS_brick68', 'pill', 5, 7, 2],
  ['BMS_brick70', 'pill', 6, 7, 2],

  ['BMS_brick72', 'pill', 1, 8, 2],
  ['BMS_brick74', 'pill', 2, 8, 2],
  ['BMS_brick76', 'pill', 3, 8, 2],
  ['BMS_brick78', 'pill', 4, 8, 2],
  ['BMS_brick80', 'pill', 5, 8, 2],
  ['BMS_brick82', 'pill', 6, 8, 2],
]
export default grid
